import { Card, Col, Row, Switch } from 'antd';
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeEvents, changeLoadingEvents } from "../../store/actions/events";
import '../../styles/homepage.less';
import Network from "../../utils/network";
import { AppEvent } from "../../utils/types/generalTypes";
import { ApplicationState } from "../../utils/types/storeTypes";
import { isDeprecated, showNotification } from "../../utils/utils";
import LoadingComponent from "../loading/loading";

import { FormattedMessage, useIntl } from 'react-intl';
import { pdfjs } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
import { ReloadTimes } from '../../utils/constants';
import EventCard from './eventCard';


pdfjs.GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js";


const Homepage = () => {
    const dispatch: any = useDispatch();
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const rUserEvents = useSelector((state: ApplicationState) => state.events.events);
    const intl = useIntl();
    const navigate = useNavigate();
    const { lang } = useParams();
    // const [userEvents, setUserEvents] = useState<UserEventApp[]>()

    const [ displayOldEvents, setDisplayOldEvents ] = useState<boolean>(false)

    useEffect(() => {
        if (currentUser === undefined)
            navigate(`/${lang}/login`);
    });

    const refreshAppEvents = useCallback(() => {
        if (currentUser) {
            dispatch(changeLoadingEvents(true));
            Network.getUserEventsApp().then(
                (response) => {
                    if (response.status === "Success") {
                        dispatch(changeEvents(response.data));
                    } else {
                        dispatch(changeEvents([]));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");

                    }
                }
            );
        }
    }, [currentUser, dispatch, intl]);

    if(
        (!rUserEvents?.loading && (rUserEvents === undefined || isDeprecated(rUserEvents.updatedAt, ReloadTimes.FAST_RELOAD)))){
            refreshAppEvents()
    }

    let today = moment();
    let defaultActiveKey: string[] = [];

    rUserEvents?.data.forEach(u => {
        let event: AppEvent = u.event;
        let startDate = event.startDate;
        let endDate = event.endDate;
        if (today.isBetween(startDate, endDate, 'day', '[]')) {
            defaultActiveKey.push(event.id.toString());
        }
    });

    return (
        <Row gutter={[10, 10]}>
            {
                rUserEvents === undefined || rUserEvents.loading ?
                    <Col xs={{ span: 24 }}>
                        <LoadingComponent />
                    </Col>
                    :
                    rUserEvents.data.length === 0 ?
                        <Col xs={{ span: 24 }}>
                            <div style={{height: 60, display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                            <p style={{ textAlign: 'center' }}><FormattedMessage defaultMessage={'No events available'} /></p>
                            </div>
                        </Col>
                        :
                        <Col xs={{ span: 24 }}>
                            <Card bodyStyle={{padding: 8}} title={<FormattedMessage defaultMessage={'My events:'} />} extra={[<Switch title={intl.formatMessage({defaultMessage: 'Display old events'})} checked={displayOldEvents} onChange={() => setDisplayOldEvents(!displayOldEvents)} />]}>
                            <Row gutter={[10, 10]} style={{ width: '100%', marginLeft: 0, marginRight: 0 }}>
                            {
                                    rUserEvents.data.filter(e => !displayOldEvents ? moment().isSameOrBefore(moment(e.event.startDate)): true).sort((a, b) => moment(a.event.startDate).isBefore(moment(b.event.startDate))? -1 : 1).map(u => {
                                        return (
                                            <Col key={`col-filtered-event-${u.event.id}`} md={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 8 }} style={{ width: '100%' }}>
                                                <EventCard
                                                    event={u.event}
                                                    loading={rUserEvents.loading}
                                                />
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                            </Card>
                        </Col>
            }
        </Row>
    );
};
export default Homepage;