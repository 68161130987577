import { combineReducers } from "@reduxjs/toolkit";
import configurations from './configurations';
import events from './events';
import planning from './planning';
import teamManagement from './teamManagement';
import user from './user';
import window from './window';

const rootReducer = combineReducers({
    user,
    window,
    planning,
    teamManagement,
    configurations,
    events,
});

export default rootReducer;
