import { faHouse, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import Cookie from 'js-cookie';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reset as confReset } from "../../store/actions/configurations";
import { reset as eventsReset } from "../../store/actions/events";
import { reset as planReset } from "../../store/actions/planning";
import { reset as teamReset } from "../../store/actions/teamManagement";
import { reset as userReset } from "../../store/actions/user";
import { reset as windowsReset } from "../../store/actions/window";
import { ApplicationState } from "../../utils/types/storeTypes";
import CustomIcon from '../common/general/customIcon';


const MenuComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const dispatch = useDispatch();
    const { lang } = useParams();
    const intl = useIntl();

    let items: MenuProps['items'] = [
        { label: intl.formatMessage({ defaultMessage: 'Home' }), key: 'dashboard', icon: <CustomIcon icon={faHouse} />, onClick: () => navigate(`/${lang}/dashboard`) }, // which is required
        {
            label: currentUser?.first_name,
            key: 'event',
            icon: <CustomIcon icon={faUser} />,
            children: [{
                label: intl.formatMessage({ defaultMessage: 'Logout' }), key: `logout`, icon: <CustomIcon icon={faRightFromBracket} />, onClick: () => {
                    dispatch(confReset());
                    dispatch(planReset());
                    dispatch(teamReset());
                    dispatch(eventsReset());
                    dispatch(windowsReset());
                    dispatch(userReset());
                    Cookie.remove('barryAppAuthtoken');
                    navigate(`/${lang}/login`);
                }
            }],
        }

    ];


    return (
        <Menu mode="horizontal" items={items} defaultSelectedKeys={['dashboard']} selectedKeys={location.pathname.split("/").filter(l => l !== "")} />
    );
};
export default MenuComponent;