import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApplicationState } from '../../../utils/types/storeTypes';
import CustomIcon from '../general/customIcon';


interface Props {
    children: ReactNode;
    backTo: {
        previous: boolean;
        link: string;
        title: string;
    } | undefined;
}


/**
 * Component that represent the container of most pages
 */
const Container = (props: Props) => {
    const { backTo, children } = props;
    const navigate = useNavigate();
    const { lang } = useParams();
    const location = useLocation();
    /**
     * Toggle the side menu navigation on smartphone opened state
     */

        return (
            <div>
                    
                {backTo ? 
                    <CustomIcon style={{marginLeft: 5, marginBottom: 5, cursor: 'pointer'}} icon={faArrowLeft} color='#00B0EF' onClick={() => (backTo.previous && location.key !== 'default')? navigate(-1) : navigate(`/${lang}/${backTo.link}`)} />
                    :
                    null
                }
                {children}
            </div >
        );
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    collapsed: state.window.navigationCollapsed,
    eventSelected: state.user.eventSelected,
});

export default connect(mapStateToProps)(Container);