import { Button, Col, Popconfirm } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Network from "../../../utils/network";
import { LoginUserSmall } from "../../../utils/types/generalTypes";
import { NetworkError, NetworkResponse } from "../../../utils/types/networkTypes";
import { showNotification } from "../../../utils/utils";
import CodeField from "../../common/fields/codeField";

interface Props {
    user: LoginUserSmall;
    setUser: (user: LoginUserSmall | undefined) => void;
    setCode: (code: string) => void;
    forgot?: boolean;
    setForgot?: (forgot: boolean) => void;
}

const LoginCodeView = (props: Props) => {

    const intl = useIntl();

    const [code, setCode] = useState<string>('');
    const [needForce, setNeedForce] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(true);

    const sendCode = (force?: boolean) => {
        setLoading(true);
        setNeedForce(false);

        Network.loginCodeSend(props.user.id, force).then(
            (res: NetworkResponse<{}> | NetworkError) => {
                if ('error' in res) {
                    if (res.code === 'MobileLogin-RegenerateWithoutForce') {
                        setNeedForce(true);
                        setLoading(false);
                        return;
                    }
                    else {
                        showNotification(intl.formatMessage({ defaultMessage: "An error occured while sending the SMS" }), 'error');
                        setLoading(false);
                        return;
                    }
                }

                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'The code has been sent' }), 'success');
            },
            error => {
                console.log("KROK", error);
                setLoading(false);
                const title = intl.formatMessage({ defaultMessage: 'An error occured while sending the SMS' });
                let message: string = '';
                try {
                    const err: NetworkError = JSON.parse(error.message);
                    if (err.code === 'MobileLogin-RegenerateTooQuick')
                        message = intl.formatMessage({ defaultMessage: 'Tried to regenerate too quickly, You need to wait 1 minute before you can regenerate a code' });
                    else if (err.code === 'MobileLogin-NotEligible')
                        message = intl.formatMessage({ defaultMessage: 'The user is not eligible' });
                    else
                        message = intl.formatMessage({ defaultMessage: 'An error occured while sending the SMS' });
                }
                catch (e) { }

                showNotification(title, 'error', message);
            }
        );
    };

    const verifyCode = () => {
        setLoading(true);
        Network.loginCodeVerify(props.user.id, code).then(
            () => {
                props.setCode(code);
            },
            error => {
                const err: NetworkError = JSON.parse(error.message);
                if (err.code === 'MobileLogin-CodeDoesNotExist')
                    showNotification(intl.formatMessage({ defaultMessage: 'The code does not exist' }), 'error');
                else if (err.code === 'MobileLogin-CodeExpired')
                    showNotification(intl.formatMessage({ defaultMessage: 'The code has expired' }), 'error');
                else
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occured while verifying the code' }), 'error');
                setValid(false);
                setLoading(false);
            }
        );
    };

    const goBack = () => {
        if (props.forgot && props.setForgot)
            props.setForgot(false);
        else
            props.setUser(undefined);
    };

    return (
        <>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ margin: '0px auto 10px auto', fontSize: 18, fontWeight: 'bold' }}>
                    <FormattedMessage defaultMessage={'Password recovery'} />
                </p>
                <p style={{ textAlign: 'center' }}>
                    {
                        props.forgot ?
                            <FormattedMessage defaultMessage={'To recover your accont and set a new password, you need to verify your identity with an SMS code'} />
                            :
                            <FormattedMessage defaultMessage={'This is your first login. To continue and set your permanent password, you first need to verify your identity with an SMS code'} />
                    }
                </p>
            </Col>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ margin: 'auto auto 20px auto' }}>
                    <Popconfirm
                        disabled={!needForce}
                        title='Do you want to force the code generation?'
                        visible={needForce}
                        onCancel={() => setNeedForce(false)}
                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                        onConfirm={() => sendCode(true)}
                        okText={<FormattedMessage defaultMessage={'Send'} />} >
                        <Button onClick={() => needForce ? null : sendCode()} disabled={loading} type='dashed'>
                            <FormattedMessage defaultMessage={'Send code'} />
                        </Button>
                    </Popconfirm>
                </div>
                <div style={{ margin: 'auto' }}>
                    <CodeField
                        type='number'
                        fields={6}
                        name='code'
                        inputMode='numeric'
                        onChange={e => {
                            setCode(e);
                            if (!valid)
                                setValid(true);
                        }}
                        isValid={valid}
                        onEnter={verifyCode}
                        autoFocus
                    />
                </div>
            </Col>

            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <Button style={{ margin: 'auto' }} disabled={loading} onClick={goBack}><FormattedMessage defaultMessage={'Back'} /></Button>
                <Button style={{ margin: 'auto' }} loading={loading} disabled={code.length !== 6} type='primary' onClick={verifyCode}><FormattedMessage defaultMessage={'Verify'} /></Button>
            </Col>
        </>
    );
};

export default LoginCodeView;