import { faArrowRight, faSignPost, faUser } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppMission } from "../../utils/types/planningTypes";
import CustomIcon from "../common/general/customIcon";

interface Props {
    mission: AppMission;
    ownMission?: boolean;
}

const MissionCard = (props: Props) => {
    const { mission } = props;

    const startDate = moment(mission.startDate);
    const endDate = moment(mission.endDate);
    const missionDate = startDate.isSame(endDate, 'day') ?
        startDate.format('DD.MM.YYYY')
        : `${startDate?.format('DD.MM.YYYY')} ⇾ ${endDate?.format('DD.MM.YYYY')}`;

    const missionTime = startDate.isSame(endDate, 'minute') ?
        startDate.format('HH:mm')
        : `${startDate?.format('HH:mm')} ⇾ ${endDate?.format('HH:mm')}`;

    const missionTitle = useMemo(() => {
        return <span className='__missions-tree-title'>
                        <span>{`${mission.poi?.title}${mission.title ? ` - ${mission.title}`:''}`}</span>                    
                </span>
    }, [mission])

    const missionUser = useMemo(() => {
        return <span>
                        <div style={{display: 'flex', justifyContent: 'center', gap: 8, alignItems: 'center'}}><CustomIcon icon={faUser} iconSize={12}  /><span>{`${mission.username}`}</span></div>
                </span>
    }, [mission])

    const navigate = useNavigate()
    const { lang, eventId } = useParams();
    ///faRoute pour le tracé dans l'icon
    return (
        <div className='eventcard-container' onClick={(e) => navigate(`/${lang}/event/${Number(eventId)}/mission/${mission.id}`)}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 8, marginRight: 8}}>
                <CustomIcon icon={faSignPost}  iconSize={28}/> 
            </div>
            <div className='eventcard-content'>
                <p className='eventcard-title'>{missionTitle}</p>
                <p className='eventcard-date'>{`${missionDate} ${missionTime}`}</p>
                {!props.ownMission ?
                    <p className='eventcard-date'>{missionUser}</p>
                : null}
            </div>
            <div className='eventcard-actions-container'>
                <div className='eventcard-action'>
                    <CustomIcon icon={faArrowRight} iconSize={17} style={{ margin: 'auto' }} />
                </div>
            </div>
        </div>
    );
};

export default MissionCard;