import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Image, Spin } from "antd";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Fallback from '../../images/fallback.png';
import { GOOGLE_STORAGE_URL } from "../../utils/constants";
import { AppEvent } from "../../utils/types/generalTypes";
import CustomIcon from "../common/general/customIcon";

interface Props {
    event: AppEvent;
    loading: boolean;
}

const EventCard = (props: Props) => {
    const { loading, event } = props;

    console.log('taddda', event)
    const startDate = moment(event.startDate);
    const endDate = moment(event.endDate);
    const eventDate = startDate.isSame(endDate, 'day') ?
        startDate.format('DD.MM.YYYY')
        : `${startDate?.format('DD.MM.YYYY')} ⇾ ${endDate?.format('DD.MM.YYYY')}`;

    const navigate = useNavigate()
    const { lang } = useParams();

    return (
        <Spin spinning={loading}>
            <div className='eventcard-container' onClick={(e) => navigate(`/${lang}/event/${event.id}/missions`)}>
                <Image
                    src={props.event.logo ? GOOGLE_STORAGE_URL + props.event.logo : ""}
                    fallback={`${Fallback}`}
                    alt='eventcard'
                    preview={false}
                    className='eventcard-image'
                    wrapperStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80 }}
                    style={{ width: 50, height: 50, margin: 'auto 16px auto 16px' }}
                />
                <div className='eventcard-content'>
                    <p className='eventcard-title'>{props.event.title}</p>
                    <p className='eventcard-date'>{eventDate}</p>
                </div>
                <div className='eventcard-actions-container'>
                    <div className='eventcard-action'>
                        <CustomIcon icon={faArrowRight} iconSize={17} style={{ margin: 'auto' }} />
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default EventCard;