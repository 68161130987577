// access to google storage
export const GOOGLE_STORAGE_URL = 'https://storage.googleapis.com/';


export const MOMENT_FORMAT_DISPLAY = "DD/MM/YYYY à HH:mm:ss";
export const MOMENT_FORMAT_DISPLAY_SHORT = "DD/MM/YY HH:mm";
export const MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:00";
export const MOMENT_MONTH_FORMAT = "YYYY-MM-DD";
export const MOMENT_CCNT_MONTH_FORMAT = "MM/YYYY";
export const MOMENT_CCNT_DAY_FORMAT = "DD/MM/YYYY";

export const YELLOW_COLOR = "#fbc02d";
export const RED_COLOR = "#e53935";
export const GREEN_COLOR = "#558b2f"

export const AvailableLanguages = [
    { label: 'English', value: 'en' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsch', value: 'de' },
    { label: 'Italiano', value: 'it' }
];


export const ColorsDefault = {
    PRIMARY: 'var(--color-primary)',
    PRIMARY_LIGHT: 'var(--color-primary-light)',
    SECONDARY: 'var(--color-secondary)',
    NORMAL: 'var(--color-normal)',
    SUCCESS: 'var(--color-success)',
    ERROR: 'var(--color-error)',
    ERROR_DARK: 'var(--color-error-dark)',
    WARNING: 'var(--color-warning)',
    SPIN: 'var(--color-spinner)',
    DARK: 'var(--color-dark)',
    BORDER: 'var(--color-border)',
    POSITIVE: 'var(--color-positive)',
    OVERTIME_PRIMARY: 'var(--color-overtime-primary)',
    OVERTIME_SECONDARY: 'var(--color-overtime-secondary)',
    UNDERTIME_PRIMARY: 'var(--color-undertime-primary)',
    UNDERTIME_SECONDARY: 'var(--color-undertime-secondary)'
};

export const ColorsBackground = {
    CONTENT: 'var(--bg-content)',
    SIDEMENU: 'var(--bg-sidemenu)',
    SIDEMENU_TOGGLE: 'var(--bg-sidemenu-toggle)',
    CARD_D1: 'var(--bg-card-d1)',
    CARD_D2: 'var(--bg-card-d2)',
    CARD_D3: 'var(--bg-card-d3)',
    DISABLED: 'var(--bg-disabled)',
    BUTTON: 'var(--bg-button)',
    BUTTON_ICON: 'var(--bg-button-icon)',
    INPUT: 'var(--bg-input',
    SELECT_SELECTED: 'var(--bg-select-selected)',
    SPIN: 'var(--bg-spinner)',
    NOTIFICATION: 'var(--bg-notification)',
    TABLE_SORT: 'var(--bg-table-sort)',
    TABLE_ROW_HOVER: 'var(--bg-table-row-hover)',
    TABLE_ROW_SELECTED: 'var(--bg-table-row-selected)',
    TABLE_ROW_HOVER_SELECTED: 'var(--bg-table-row-hover-selected)',
    ALERT_INFO: 'var(--bg-alert-info)',
    TAG_PRIMARY: 'var(--bg-tag-primary)',
    POI_BUTTON: 'var(--bg-poi-button)',
    ROW_RED: 'var(--bg-row-red)',
    ROW_GREEN: 'var(--bg-row-green)',
    ROW_ORANGE: 'var(--bg-row-orange)',
    ROW_BLUE: 'var(--bg-row-blue)',
};

export const ColorsBorder = {
    CONTENT: 'var(--border-content)',
    CARD_D2: 'var(--border-card-d2)',
    CARD_D3: 'var(--border-card-d3)',
    BUTTON: 'var(--border-button)',
    INPUT: 'var(--border-input)',
    TABLE: 'var(--border-table)',
    DISABLED: 'var(--border-disabled)',
    ALERT_INFO: 'var(--border-alert-info)',
    TAG_PRIMARY: 'var(--border-tag-primary)',
};

export const ColorsText = {
    PRIMARY: 'var(--text-primary)',
    PLACEHOLDER: 'var(--text-placeholder)',
    DISABLED: 'var(--text-disabled)',
    EMPTY: 'var(--text-empty)',
    ERROR: 'var(--text-error)',
};

export const ColorsIcon = {
    RED: 'var(--icon-red)',
    ORANGE: 'var(--icon-orange)',
    GREEN: 'var(--icon-green)',
    BLUE: 'var(--icon-blue)',
    WHITE: 'var(--icon-white)',
};

export const ColorsTable = {
    ROW_RED: 'var(--bg-row-red)',
    ROW_GREEN: 'var(--bg-row-green)',
    ROW_ORANGE: 'var(--bg-row-orange)',
    ROW_BLUE: 'var(--bg-row-blue)',
};

export const ColorsShadow = {
    PRIMARY: 'var(--shadow-primary)',
    BOX: 'var(--shadow-box)',
};

export const ColorsInvoices = {
    PAID: 'var(--invoice-paid)',
    UNPAID: 'var(--invoice-unpaid)',
    PROCESS: 'var(--invoice-process)',
    CANCELLED: 'var(--invoice-canceled)',
    PARTIAL: 'var(--invoice-partial)',
    OVERDUE: 'var(--invoice-overdue)',
};

export const ColorsInvoicesIcons = {
    PAID: 'var(--invoice-icon-paid)',
    UNPAID: 'var(--invoice-icon-unpaid)',
    PROCESS: 'var(--invoice-icon-process)',
    CANCELED: 'var(--invoice-icon-canceled)',
    PARTIAL: 'var(--invoice-icon-partial)',
};
export const enum ReloadTimes {
    SPEED_RELOAD = 1,
    FAST_RELOAD = 5,
    MEDIUM_RELOAD = 15,
    LONG_RELOAD = 30,
}
