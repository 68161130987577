import { Layout as AntLayout, BackTop, Button } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Cookie from 'js-cookie';
import moment from 'moment';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../images/logo/barry_events_full2.png';
import LanguageProvider from '../../languageProvider';
import { changeEmergency, changeLoadingEmergency, changeLoadingMissions, changeLoadingUserMissions, changeMissions, changeUserMissions } from '../../store/actions/events';
import { changeUserDetails } from '../../store/actions/user';
import { ReloadTimes } from '../../utils/constants';
import Network from '../../utils/network';
import { ApplicationState } from '../../utils/types/storeTypes';
import { isDeprecated, showNotification } from '../../utils/utils';
import LanguageMenu from '../common/fields/languageMenu';
import MenuComponent from '../menu/menu';
import CheckVersion from './checkVersion';
import Private from './private';

export const Layout = () => {
    const location = useLocation();
    const { lang } = useParams();
    const barryAppAuthtoken = Cookie.get('barryAppAuthtoken');

    const url = location.pathname.split("/");
    if (url.length > 2 && url[2] !== undefined && url[2] !== '' && lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
        return <Outlet />;
    } else {
        let locale = 'fr';
        if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
            locale = lang;
        }
        if (barryAppAuthtoken) {
            return <Navigate to={`/${locale}/dashboard`} replace />;
        } else {
            return <Navigate to={`/${locale}/login`} replace />;
        }

    }
};
export default Layout;

export const LocaleLayout = () => {
    const location = useLocation();
    const { lang } = useParams();
    const height = useSelector((state: ApplicationState) => state.window.height)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const barryAppAuthtoken = Cookie.get('barryAppAuthtoken');
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);

    const changeLanguage = useCallback((lang: string) => {
        if (currentUser && lang && currentUser.language !== lang) {
            Network.changeUserLanguage(lang).then(
                (response) => {
                    if (response.error === false) {
                        dispatch(changeUserDetails(response.data));
                    }
                },
                error => {
                    if (error.message === "AUTH_FAILED") {
                        navigate(`${lang}/login`);
                    }
                }
            );
        }
    }, [currentUser, dispatch, navigate]);

    const url = location.pathname.split("/");
    if (url.length > 2 && url[2] !== undefined && url[2] !== '' && lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
        if (barryAppAuthtoken) {
            changeLanguage(lang);
            moment.locale(lang ? lang : 'fr');
            return (
                <LanguageProvider>
                    <Private>
                        <CheckVersion>
                            <AntLayout className="layout-container">
                                <BackTop />

                                <Header>
                                    <div className="logo" style={{ float: 'right' }} >
                                        <img style={{ maxHeight: '32px' }} src={Logo} alt="logo" />
                                    </div>
                                    <LanguageMenu lang={lang} navigateTo='dashboard' />
                                    <MenuComponent />
                                </Header>
                                <Content style={{height: `calc( ${height}px - 128px )`, overflow: 'scroll'}}>
                                    <Outlet />
                                </Content>
                                <Footer style={{ textAlign: 'center', backgroundColor: '#ffffff' }}>
                                    {`Barry ${moment().format('YYYY')} © Powered by `}<Link to='https://webevolutions.ch/fr/'>WebEvolutions</Link>
                                </Footer>
                            </AntLayout>
                        </CheckVersion>
                    </Private >
                </LanguageProvider>
            );
        } else {
            return <LanguageProvider><Outlet /></LanguageProvider>;
        }
    } else {
        let locale = 'fr';
        if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
            locale = lang;
        }
        if (barryAppAuthtoken) {
            return <Navigate to={`/${locale}/dashboard`} replace />;
        } else {
            return <Navigate to={`/${locale}/login`} replace />;
        }

    }
};

export const EventLayout = () => {
    const dispatch = useDispatch();
    const { lang, eventId } = useParams();
    const intl = useIntl();
    const navigate =  useNavigate();

    const rMissions = useSelector((state: ApplicationState) => state.events.missions);
    const rEmergency = useSelector((state: ApplicationState) => state.events.emergency);
    const rUsermissions = useSelector((state: ApplicationState) => state.events.missions);
    const location = useLocation();

    const url = location.pathname.split("/");

    const refreshMissions = useCallback(() => {

        if (eventId) {
            dispatch(changeLoadingMissions(true));
            dispatch(changeLoadingUserMissions(true));
            Network.getMissionsApp(Number(eventId)).then(
                (response) => {
                    if (response.status === "Success") {
                        dispatch(changeMissions(Number(eventId), response.data));
                        dispatch(changeUserMissions(Number(eventId), response.usersData));
                    } else {
                        dispatch(changeMissions(Number(eventId), []));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");
                    }
                },
                error => {
                    if (error.message === "AUTH_FAILED") {
                        dispatch(changeLoadingUserMissions(false));
                        navigate(`${lang}/login`);
                    } else {
                        dispatch(changeLoadingUserMissions(false));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");
                    }
                }
            );
        }
    }, [dispatch, navigate, eventId, lang, intl]);

    const refreshEmergency = useCallback(() => {

        if (eventId) {
            dispatch(changeLoadingEmergency(true));
            Network.getEmergency(Number(eventId)).then(
                (response) => {
                    if (response.error === false) {
                        dispatch(changeEmergency(Number(eventId), response.data));
                    } else {
                        dispatch(changeEmergency(Number(eventId), {textHeader: '', contacts: []}));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading emergency details' }), "error");
                    }
                },
                error => {
                    if (error.message === "AUTH_FAILED") {
                        dispatch(changeLoadingEmergency(false));
                        navigate(`${lang}/login`);
                    } else {
                        dispatch(changeLoadingEmergency(false));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading emergency details' }), "error");
                    }
                }
            );
        }
    }, [dispatch, navigate, eventId, lang, intl]);

    console.log('tagagaa', eventId, rMissions, rUsermissions)
    if(
        (!rMissions?.loading && (rMissions === undefined || rMissions.eventId !== Number(eventId) || isDeprecated(rMissions.updatedAt, ReloadTimes.FAST_RELOAD)))  || 
        (!rUsermissions?.loading && (rUsermissions === undefined || rUsermissions.eventId !== Number(eventId) || isDeprecated(rUsermissions.updatedAt, ReloadTimes.FAST_RELOAD)))){
            refreshMissions()
    }

    if(
        (!rEmergency?.loading && (rEmergency === undefined || rEmergency.eventId !== Number(eventId) || isDeprecated(rEmergency.updatedAt, ReloadTimes.MEDIUM_RELOAD)))){
            refreshEmergency()
    }

    const alreadyAnEmergency = url.length >=5 && url[4] !== 'emergency'
    return (
        <div>
            <Outlet />
            {alreadyAnEmergency ? <div style={{width: 'calc(100% - 25px)', position: 'fixed', bottom: 5}}>
                <Button onClick={() => navigate(`/${lang}/event/${eventId}/emergency`)} style={{width: '100%', height: 45, backgroundColor: 'red', border: 'none', fontSize: '20px'}} type='primary'><FormattedMessage defaultMessage={'Emergency'} /></Button>
            </div>
            :
            null}
        </div>
    );
}


export const NotFoundLayout = () => {
    const { lang } = useParams();
    const barryAppAuthtoken = Cookie.get('barryAppAuthtoken');

    let locale = 'fr';
    if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
        locale = lang;
    }

    if (barryAppAuthtoken) {
        return <Navigate to={`/${locale}/dashboard`} replace />;

    } else {
        return <Navigate to={`/${locale}/login`} replace />;
    }
};