import { StoreAction, TeamManagementState } from '../../utils/types/storeTypes';

export const TEAM_INIT_STATE: TeamManagementState = {
    user: undefined,
    users: [],
    eventUsers: undefined,
    usersGroups: undefined,
    group: undefined,
    groups: undefined,
    groupsLoading: false,
    groupsUsers: undefined,
    eventUsersLoading: false,
    usersLoading: false,
};

export const CHANGE_USER = 'CHANGE_USER';
export const CHANGE_USERS = 'CHANGE_USERS';
export const CHANGE_EVENT_USERS = 'CHANGE_EVENT_USERS';
export const CHANGE_USERS_GROUPS = 'CHANGE_USERS_GROUPS';
export const CHANGE_GROUP = 'CHANGE_GROUP';
export const CHANGE_GROUPS = 'CHANGE_GROUPS';
export const CHANGE_GROUPS_LOADING = 'CHANGE_GROUPS_LOADING';
export const CHANGE_GROUPS_USERS = 'CHANGE_GROUPS_USERS';
export const CHANGE_EVENT_USERS_LOADING = 'CHANGE_EVENT_USERS_LOADING';
export const TOGGLE_USERS_LOADING = 'TOGGLE_USERS_LOADING';
export const RESET_TEAMMANAGEMENT = 'RESET_TEAMMANAGEMENT';

const teamManagement = (state: TeamManagementState = TEAM_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_USER:
            return {
                ...state,
                user: action.data,
            }
        case CHANGE_USERS:
            return {
                ...state,
                users: action.data,
                usersLoading: false,
            }
        case CHANGE_EVENT_USERS:
            return {
                ...state,
                eventUsers: action.data,
                eventUsersLoading: false,
            }
        case CHANGE_EVENT_USERS_LOADING:
            return {
                ...state,
                eventUsersLoading: action.data,
            }
        case CHANGE_USERS_GROUPS:
            return {
                ...state,
                usersGroups: action.data,
            }
        case CHANGE_GROUP:
            return {
                ...state,
                group: action.data,
            }
        case CHANGE_GROUPS:
            return {
                ...state,
                groups: action.data,
                groupsLoading: false,
            }
        case CHANGE_GROUPS_LOADING:
            return {
                ...state,
                groupsLoading: action.data,
            }
        case CHANGE_GROUPS_USERS:
            return {
                ...state,
                groupsUsers: action.data,
            }
        case TOGGLE_USERS_LOADING:
            return {
                ...state,
                usersLoading: action.data,
            };
        case RESET_TEAMMANAGEMENT:
            return {
                ...TEAM_INIT_STATE,
                groups: state.groups,
            };
        default:
            return state;
    }
};

export default teamManagement;