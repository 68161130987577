import { cloneDeep } from 'lodash';
import moment from 'moment';
import { EventsState, StoreAction } from '../../utils/types/storeTypes';

export const EVENT_INIT_STATE: EventsState = {
    missions: undefined,
    userMissions: undefined,
    events: undefined,
    emergency: undefined,
};

export const CHANGE_MISSIONS = 'CHANGE_MISSIONS';
export const CHANGE_LOADING_MISSIONS = 'CHANGE_LOADING_MISSIONS';
export const CHANGE_USERMISSIONS = 'CHANGE_USERMISSIONS';
export const CHANGE_LOADING_USERMISSIONS = 'CHANGE_LOADING_USERMISSIONS';
export const CHANGE_EMERGENCY = 'CHANGE_EMERGENCY';
export const CHANGE_LOADING_EMERGENCY = 'CHANGE_LOADING_EMERGENCY';
export const CHANGE_EVENTS = 'CHANGE_EVENTS';
export const CHANGE_LOADING_EVENTS = 'CHANGE_LOADING_EVENTS';
export const RESET_EVENTS = 'RESET_EVENTS';

const planning = (state: EventsState = EVENT_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_MISSIONS:
            return {
                ...state,
                missions: {
                    loading: false,
                    eventId: action.eventId,
                    data: cloneDeep(action.data),
                    updatedAt: moment().toISOString(),
                },
            }
        case CHANGE_LOADING_MISSIONS:
            return {
                ...state,
                missions: {
                    ...state.missions,
                    loading: action.data,
                }
            }
        case CHANGE_USERMISSIONS:
            return {
                ...state,
                userMissions: {
                    loading: false,
                    eventId: action.eventId,
                    data: cloneDeep(action.data),
                    updatedAt: moment().toISOString(),
                },
            }
        case CHANGE_LOADING_USERMISSIONS:
            return {
                ...state,
                userMissions: {
                    data:[],
                    ...state.userMissions,
                    loading: action.data,
                }
            }
        case CHANGE_EMERGENCY:
            return {
                ...state,
                emergency: {
                    loading: false,
                    eventId: action.eventId,
                    data: cloneDeep(action.data),
                    updatedAt: moment().toISOString(),
                },
            }
        case CHANGE_LOADING_EMERGENCY:
            return {
                ...state,
                emergency: {
                    data:[],
                    ...state.userMissions,
                    loading: action.data,
                }
            }
        case CHANGE_EVENTS:
            return {
                ...state,
                events: {
                    loading: false,
                    data: cloneDeep(action.data),
                    updatedAt: moment().toISOString()
                }
            }
        case CHANGE_LOADING_EVENTS:
            return {
                ...state,
                events: {
                    data:[],
                    ...state.events,
                    loading: action.data,
                }
            }
        case RESET_EVENTS:
            return EVENT_INIT_STATE;
        default:
            return state;
    }
};

export default planning;