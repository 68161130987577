import { faPhone, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { Button, Spin } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ApplicationState } from "../../utils/types/storeTypes";
import CustomIcon from "../common/general/customIcon";
import Container from "../common/navigations/container";

const Emergency = () => {
    const { eventId } = useParams();
    const intl = useIntl();
    const rEmergency = useSelector((state: ApplicationState) => state.events.emergency);
    const height = useSelector((state: ApplicationState) => state.window.height)
    return <Container backTo={{link: `event/${eventId}/missions`, title: intl.formatMessage({ defaultMessage: 'Missions' }), previous: true}}>
        {rEmergency === undefined || rEmergency.loading?
                <Spin
                    style={{ marginTop: '36px', width: "100%" }}
                    size="large"
                    indicator={<CustomIcon icon={faSpinnerThird} iconSize={100} spin />}
                />
            :
            <div id={'emergency-root'} >
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <h1 style={{fontSize: '28px', color: 'black'}}>{intl.formatMessage({defaultMessage: 'Emergency'})}</h1>
                </div>
                <div className="emergency-phone-header" >
                    <pre style={{ width: '100%', fontFamily: 'inherit', whiteSpace: 'break-spaces', fontSize: '12px', textAlign: 'center', overflowWrap: "break-word" }}>{rEmergency.data.textHeader ?? "<Texte de l'en-tête>"}</pre>
                </div>
                <div>
                    <span className="emergency-phone-button-container">
                        {/* <p style={{ cursor: 'pointer', textAlign: 'center', paddingRight: 10, paddingLeft: 10, minWidth: 110, maxWidth: 115, minHeight: 28, backgroundColor: 'var(--icon-red)', color: 'black', fontSize: '12px', marginBottom: '20px' }} className="emergency-phone-button"> */}
                            <a style={{textDecoration: 'none', width: '100%', textAlign: 'center'}} href={`tel:${rEmergency.data.buttonPhone}`}><Button style={{cursor: 'pointer', backgroundColor: 'red', border: 'none', width: '80%', minHeight: '40px'}} type={'primary'}>{rEmergency.data.buttonName ?? "<Titre du bouton>"}</Button></a>
                        {/* </p> */}
                    </span>
                </div>
                <div style={{marginTop: 25, height: `calc( ${height}px - 450px )`, overflow: 'scroll'}}>
                    {rEmergency.data ? 
                        rEmergency.data.contacts.map((c, i) => (
                            <div style={{ borderRadius: '30px', marginLeft: 5, marginRight: 5, height: 40 }} className="emergency-phone-contact" key={`emergency-phone-contact-${i}`}>
                                <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5, fontSize: '12px' }}>
                                    <p>{c.name ?? ''}</p>
                                    {/*<p style={{ fontSize: '12px' }}>{c.location??''}</p>*/}
                                </div>
                                <div style={{ borderRadius: '50%', backgroundColor: 'var(--planning-outside-period)', width: 25, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <a style={{textDecoration: 'none', }} href={`tel:${c.phone}`}><CustomIcon style={{cursor: 'pointer'}} icon={faPhone} /></a>
                                </div>
                            </div>
                        ))
                    :
                        null
                    }
                    </div>
            </div>
            }


    </Container>
}
export default Emergency