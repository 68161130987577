import moment from 'moment';
import { ConfigurationsState, StoreAction } from '../../utils/types/storeTypes';

export const CONFIGURATIONS_INIT_STATE: ConfigurationsState = {
    occupancyRates: [],
    typesOfDay: [],
    typesOfDayOff: [],
    project: [],
    appVersion: {
        last_updated: undefined,
        data: {
            name: "barry_admin",
            version: process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "1.0.0",
            modified: moment(),
            created: moment()
        }
    }
};

export const CHANGE_APP_VERSION = 'CHANGE_APP_VERSION'
export const CHANGE_OCCUPANCY_RATES = 'CHANGE_OCCUPANCY_RATES';
export const CHANGE_TYPES_OF_DAY = 'CHANGE_TYPES_OF_DAY';
export const CHANGE_TYPES_OF_DAY_OFF = 'CHANGE_TYPES_OF_DAY_OFF';
export const CHANGE_PROJECT = 'CHANGE_PROJECT';
export const RESET_CONFIGURATION = 'RESET_CONFIGURATION'

const configurations = (state: ConfigurationsState = CONFIGURATIONS_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_APP_VERSION:
            return {
                ...state,
                appVersion: { last_updated: moment(), data: action.data }
            }
        case CHANGE_OCCUPANCY_RATES:
            return {
                ...state,
                occupancyRates: action.data,
            }
        case CHANGE_TYPES_OF_DAY:
            return {
                ...state,
                typesOfDay: action.data,
            }
        case CHANGE_TYPES_OF_DAY_OFF:
            return {
                ...state,
                typesOfDayOff: action.data,
            }
        case CHANGE_PROJECT:
            return {
                ...state,
                project: action.data,
            }
        case RESET_CONFIGURATION:
            return CONFIGURATIONS_INIT_STATE;
        default:
            return state;
    }
};

export default configurations;