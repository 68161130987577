import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { Card, Col, Row, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ApplicationState } from "../../utils/types/storeTypes";
import CustomIcon from '../common/general/customIcon';
import Container from '../common/navigations/container';
import MissionCard from './missionCard';



const EventDetail = () => {
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const rMissions = useSelector((state: ApplicationState) => state.events.missions);
    const rUsermissions = useSelector((state: ApplicationState) => state.events.userMissions);
    const { eventId, lang } = useParams();
    const intl = useIntl();


    return (
        <Container backTo={{ title: intl.formatMessage({ defaultMessage: 'Events' }), link: `${lang}/dashboard`, previous: false }}>
        <Row gutter={[10, 10]}>

            <Card bodyStyle={{padding: 8}} style={{width: '100%'}} title={<FormattedMessage defaultMessage={'My missions'} />}>
            {rMissions?.loading?
                <Spin
                    style={{ marginTop: '36px', width: "100%" }}
                    size="large"
                    indicator={<CustomIcon icon={faSpinnerThird} iconSize={100} spin />}
                />
            :
            rMissions?.data.length === 0 ?
                <Col xs={{ span: 24 }}>
                    <p><FormattedMessage defaultMessage={'No mission available'} /></p>
                </Col>
                :
                <Row gutter={[10, 10]} style={{ width: '100%', marginLeft: 0, marginRight: 0 }}>

                    {
                        rMissions?.data.map(m => {
                            return (
                                <Col key={`eventdetail-event${eventId}-user${currentUser?.id}-mission${m.id}`} md={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 8 }} style={{ width: '100%' }}>
                                    <MissionCard ownMission mission={m}/>
                                </Col>
                            );
                        })
                    }
                </Row>
            }
            </Card>
            <Card bodyStyle={{padding: 8}} style={{width: '100%'}} title={<FormattedMessage defaultMessage={"My team's missions"} />}>
                {rUsermissions?.loading?
                                <Spin
                                    style={{ marginTop: '36px', width: "100%" }}
                                    size="large"
                                    indicator={<CustomIcon icon={faSpinnerThird} iconSize={100} spin />}
                                />
                            :
                            rUsermissions?.data.length === 0 ?
                                <Col xs={{ span: 24 }}>
                                    <p><FormattedMessage defaultMessage={'No mission available'} /></p>
                                </Col>
                                :
                    <Row gutter={[10, 10]} style={{ width: '100%', marginLeft: 0, marginRight: 0 }}>
                    {
                        rUsermissions?.data.map(m => {
                            return (
                                <Col key={`eventdetail-event${eventId}-user${currentUser?.id}-mission${m.id}`} md={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 8 }} style={{ width: '100%' }}>
                                    <MissionCard mission={m} />
                                </Col>
                            );
                        })
                    }
                </Row>
            }
            </Card>
        </Row>
        </Container>
    );
};
export default EventDetail;