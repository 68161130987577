import { AppVersion } from "../../utils/types/generalTypes";
import { CompanyEvent, PlanningOccupancyRate, TypeOfDay } from "../../utils/types/planningTypes";
import { StoreAction } from "../../utils/types/storeTypes";
import { CHANGE_APP_VERSION, CHANGE_OCCUPANCY_RATES, CHANGE_PROJECT, CHANGE_TYPES_OF_DAY, CHANGE_TYPES_OF_DAY_OFF, RESET_CONFIGURATION } from "../reducer/configurations";

/**
 * Change stored occupancy rates
 * @param occupancyRates the new occupancy rates to store
 * @return a store action
 */
export const changeOccupancyRates = (occupancyRates: PlanningOccupancyRate[]): StoreAction => ({ type: CHANGE_OCCUPANCY_RATES, data: occupancyRates });

export const changeAppVersion = (appVersion: AppVersion): StoreAction => ({ type: CHANGE_APP_VERSION, data: appVersion });

/**
 * Change stored types of day
 * @param typesOfDay the new types of day to store
 * @return a store action
 */
export const changeTypesOfDay = (typesOfDay: TypeOfDay[]): StoreAction => ({ type: CHANGE_TYPES_OF_DAY, data: typesOfDay });

/**
 * Change stored types of day
 * @param typesOfDay the new types of day to store
 * @return a store action
 */
export const changeTypesOfDayOff = (typesOfDayOff: TypeOfDay[]): StoreAction => ({ type: CHANGE_TYPES_OF_DAY_OFF, data: typesOfDayOff });

/**
 * Change stored projects
 * @param project the new projects to store
 * @return a store action
 */
export const changeProject = (project: CompanyEvent[]): StoreAction => ({ type: CHANGE_PROJECT, data: project });

/**
 * Reset all stored values
 * @return a store action
 */
export const reset = () => ({ type: RESET_CONFIGURATION });