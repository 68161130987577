import { Route, Routes } from "react-router-dom";
import Emergency from "../emergency/emergency";
import EventDetail from "../eventDetail/eventDetail";
import Homepage from "../homepage/homepage";
import LoginSMS from "../login/loginSMS";
import MissionDetail from "../missionDetail/missionDetail";
import Layout, { EventLayout, LocaleLayout, NotFoundLayout } from '../specialRoutes/layouts';
import NotPrivate from '../specialRoutes/notPrivate';

const Root = () => {


    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path=':lang' element={<LocaleLayout />}>
                        <Route path="dashboard" element={<Homepage />} />
                        <Route path='event/:eventId' element={<EventLayout />}>
                            <Route path="missions" element={<EventDetail />} />
                            <Route path="mission/:missionId" element={<MissionDetail />} />
                            <Route path="emergency" element={<Emergency />} />
                        </Route>
                        <Route path="login" element={<NotPrivate><LoginSMS /></NotPrivate>} />
                        <Route path="*" element={<NotFoundLayout />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
};

export default Root;