import { ThunkAction } from 'redux-thunk';
import { UserEventApp } from '../../utils/types/generalTypes';
import { AppEmergency, AppMission } from '../../utils/types/planningTypes';
import { PlanningState, StoreAction } from '../../utils/types/storeTypes';
import { CHANGE_EMERGENCY, CHANGE_EVENTS, CHANGE_LOADING_EMERGENCY, CHANGE_LOADING_EVENTS, CHANGE_LOADING_MISSIONS, CHANGE_LOADING_USERMISSIONS, CHANGE_MISSIONS, CHANGE_USERMISSIONS, RESET_EVENTS } from '../reducer/events';

export type Effect = ThunkAction<any, PlanningState, any, StoreAction>;

export const changeMissions = (eventId: number, missions: AppMission[]): StoreAction => {
    return { type: CHANGE_MISSIONS, data: missions, eventId };
}
export const changeLoadingMissions = (loading: boolean): StoreAction => {
    return { type: CHANGE_LOADING_MISSIONS, data: loading };
}
export const changeUserMissions = (eventId: number, missions: AppMission[]): StoreAction => {
    return { type: CHANGE_USERMISSIONS, data: missions, eventId };
}
export const changeLoadingUserMissions = (loading: boolean): StoreAction => {
    return { type: CHANGE_LOADING_USERMISSIONS, data: loading };
}
export const changeEmergency = (eventId: number, missions: AppEmergency): StoreAction => {
    return { type: CHANGE_EMERGENCY, data: missions, eventId };
}
export const changeLoadingEmergency = (loading: boolean): StoreAction => {
    return { type: CHANGE_LOADING_EMERGENCY, data: loading };
}
export const changeEvents = (events: UserEventApp[]): StoreAction => {
    return { type: CHANGE_EVENTS, data: events };
}
export const changeLoadingEvents = (loading: boolean): StoreAction => {
    return { type: CHANGE_LOADING_EVENTS, data: loading };
}
/**
 * Reset events reducer
 */
export const resetEvents = () => ({ type: RESET_EVENTS });
export const reset = () => ({ type: RESET_EVENTS });