import {
    CHANGE_CURRENT_COMPANY,
    CHANGE_EVENT_SELECTED,
    CHANGE_LOADING_EVENT_SELECTED,
    GET_CURRENT_USER_DETAILS_FINISHED,
    GET_CURRENT_USER_DETAILS_STARTED,
    RESET_USER,
    TOGGLE_USER_LOADING,
} from '../reducer/user';

import { useNavigate } from 'react-router-dom';
import { ThunkAction } from 'redux-thunk';
import Network from '../../utils/network';
import { Company, User } from '../../utils/types/generalTypes';
import { CompanyEvent } from '../../utils/types/planningTypes';
import { StoreAction, UserState } from '../../utils/types/storeTypes';

export type Effect = ThunkAction<any, UserState, any, StoreAction>;

/**
 * Get detail of current connected user
 */
export const getCurrentUser = (): Effect => (dispatch) => {
    dispatch({
        type: GET_CURRENT_USER_DETAILS_STARTED,
    });


    Network.getUserDetails().then(
        response => {
            dispatch({
                type: GET_CURRENT_USER_DETAILS_FINISHED,
                data: response,
            });
        },
        error => {
            console.error(error);
            dispatch({
                type: GET_CURRENT_USER_DETAILS_FINISHED,
                data: {},
            });
        }
    );
};

export const loadEventSelected = (e: number, lang: any, history: any): Effect => (dispatch, getState) => {
    const navigate = useNavigate()
    dispatch({
        type: CHANGE_LOADING_EVENT_SELECTED,
        data: true
    });

    Network.getEvents(e).then(
        (response: CompanyEvent[]) => {
            dispatch({
                type: CHANGE_EVENT_SELECTED,
                data: response,
            });
            dispatch({
                type: CHANGE_LOADING_EVENT_SELECTED,
                data: false
            });
        },
        () => {
            dispatch({
                type: CHANGE_EVENT_SELECTED,
                data: undefined,
            });
            dispatch({
                type: CHANGE_LOADING_EVENT_SELECTED,
                data: false
            });
            navigate(`/${lang}/dashboard`);
        },
    );
};

/**
 * Change the current stored user
 * @param user the user's details
 */
export const changeUserDetails = (user: User): StoreAction => ({ type: GET_CURRENT_USER_DETAILS_FINISHED, data: user });

/**
 * Change the current stored company
 * @param company the company
 */
export const changeCompany = (company: Company): StoreAction => ({ type: CHANGE_CURRENT_COMPANY, data: company });


/**
 * Change the current stored company
 * @param company the company
 */
export const changeEventSelected = (eventSelected: CompanyEvent | undefined): StoreAction => ({ type: CHANGE_EVENT_SELECTED, data: eventSelected });

/**
 * Toggle the stored loading property
 * @param state the new state
 * @returns a store action
 */
export const toggleLoading = (state: boolean): StoreAction => ({ type: TOGGLE_USER_LOADING, data: state });


export const reset = () => ({ type: RESET_USER });