import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faA, faCheck, faCompass, faGear, faGlobe, faGrid2, faHand, faLock, faPaperPlane, faPhone, faQuestion, faShield } from '@fortawesome/pro-solid-svg-icons';
import useResizeObserver from '@react-hook/resize-observer';
import { Button, Card, Col, Modal, Popconfirm, Row, Spin } from 'antd';
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { isIOS } from 'react-device-detect';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import '../../styles/missionDetail.less';
import getFormat from '../../utils/Lang';
import { GOOGLE_STORAGE_URL } from "../../utils/constants";
import Network from "../../utils/network";
import { POI, PoiImage } from "../../utils/types/generalTypes";
import { AppMission } from "../../utils/types/planningTypes";
import { ApplicationState } from "../../utils/types/storeTypes";
import { haversine, showNotification } from "../../utils/utils";
import CustomIcon from '../common/general/customIcon';
import Container from '../common/navigations/container';
import LoadingComponent from "../loading/loading";
import IconComponent from "./iconComponentv2";





interface IMProps {
    item: ReactImageGalleryItem,
    poiImage: PoiImage,
    poiId?: number,
    callback: Function;

}

export const RenderImage = (props: IMProps) => {

    const target = useRef<HTMLImageElement>(null);
    const [selectedImageSize, setSelectedImageSize] = useState<{ width: number, height: number; }>({ width: 0, height: 0 });
    useResizeObserver(target, (entry: ResizeObserverEntry) => {
        console.log("TARGET ENTRY", entry);

        if (entry.contentRect.width !== 0 || entry.contentRect.height !== 0) {
            console.log("TARGET STORED", { width: entry.contentRect.width, height: entry.contentRect.height });
            setSelectedImageSize({ width: entry.contentRect.width, height: entry.contentRect.height });
        }
    });
    useLayoutEffect(() => {
        if (target.current) {
            let newSize = target.current.getBoundingClientRect();
            console.log("NEW SIZEX", newSize);
            if (newSize.width !== 0 && newSize.height !== 0) {
                setSelectedImageSize({ width: newSize.width, height: newSize.height });
            }
        }


    }, [target]);
    //const imageRef = createRef<HTMLImageElement>()
    //resizeObserver.observe(imageRef)
    return (
        <div >
            {/* <div className='active-image-area' onClick={() => setImageToOpen(item.original)} > */}
            <div className='active-image-area' onClick={() => props.callback(props.item.original)} >
                <div className='active-image-container' id={"main" + props.poiId}>
                    <img ref={target} alt="Poi img" className='security-active-image' src={`${props.item.original}`} />
                    {props.poiImage.icons.map((icon, j) => {

                        if (selectedImageSize.width !== 0 && selectedImageSize.height !== 0) {
                            return (
                                <IconComponent key={`icon-${props.poiImage?.id}-${icon.id}-${selectedImageSize.height}-${selectedImageSize.width}`} activeIcon={icon} parentHeight={selectedImageSize.height} parentWidth={selectedImageSize.width} />
                            );
                        } else {
                            return (
                                <Spin
                                    key={`spinner-key-${j}`}
                                    style={{ marginTop: '36px', width: "100%" }}
                                    size="large"
                                    spinning={(selectedImageSize.width === 0 || selectedImageSize.height === 0)}
                                    indicator={<CustomIcon icon={faSpinnerThird} duoTone="#00B0EF" iconSize={100} spin />}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );

    // return <img alt="image" onClick={() => setImageToOpen(item.original)} className='image-gallery-image' src={`${item.original}`} />;
};



const MissionDetail = () => {
    const navigate = useNavigate();
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);

    const [poi, setPoi] = useState<POI>();
    const [imageToOpen, setImageToOpen] = useState<string>();
    const [positionValidated, setPositionValidated] = useState<boolean>(false);
    const [missionConfirmed, setMissionConfirmed] = useState<boolean>(false);
    const [teamMission, setTeamMission] = useState<boolean>(false);
    const [missionLoading, setMissionLoading] = useState<boolean>(false);
    //const [selectedImageSize, setSelectedImageSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    const [gettingPosition, setGettingPosition] = useState<boolean>(false);
    const [poiLoading, setPoiLoading] = useState<boolean>(true);
    const [longWaitPositionMessage, setLongWaitPositionMessage] = useState<string>();
    const appWidth = useSelector((state: ApplicationState) => state.window.width);
    const [missionConfirmedLoading, setMissionConfirmedLoading] = useState<boolean>(false);

    const [geolocDenied, setGeolocDenied] = useState<boolean>(false);
    const [geolocHelp, setGeolocHelp] = useState<boolean>(false);

    const { lang, eventId, missionId } = useParams();
    const intl = useIntl();

    const [ mission, setMission ] = useState<AppMission>();

    useEffect(() => {
        setMissionLoading(true);
        Network.getMissionApp(Number(missionId), Number(eventId)).then(
            (response) => {
                if (response.status === "Success") {
                    setMission(response.data);
                    setMissionConfirmed(response.data.confirmed)
                    setPositionValidated(response.data.isInPlace)
                    if(currentUser)
                        setTeamMission(response.data.userId !== currentUser.id)
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");
                }
                setMissionLoading(false);

            },
            error => {
                if (error.message === "AUTH_FAILED") {
                    navigate(`${lang}/login`);
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");
                    setMissionLoading(false);
                }
            }
        );
    }, [eventId, intl, lang, missionId, navigate, currentUser])

    useEffect(() => {
        setPoiLoading(true);
        if (mission && mission.poi && eventId) {
            console.log("MISSION", mission);
            Network.getPoi(Number(eventId), mission.poi.id).then(
                (response) => {
                    if (response.status === "Success") {
                        let poi: POI = response.data;
                        setPoi(poi);
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading poi' }), "error");
                    }
                },
                error => {
                    if (error.message === "AUTH_FAILED") {
                        navigate(`/${lang}/login`);
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading poi' }), "error");
                    }
                }
            ).finally(
                () => setPoiLoading(false)
            );
        } else {
            setPoiLoading(false);
        }
    }, [mission, navigate, eventId, lang, intl]);


    let images: ReactImageGalleryItem[] = [];
    if (poi?.poiImages) {
        for (let img of poi.poiImages) {
            if (img.isSat) {
                images.push({ original: img.image, thumbnail: img.image, thumbnailHeight: 50, renderItem: (e) => <RenderImage item={e} poiImage={img} poiId={poi.id} callback={setImageToOpen} /> });
            } else {
                images.push({ original: GOOGLE_STORAGE_URL + img.image, thumbnail: GOOGLE_STORAGE_URL + img.image, thumbnailHeight: 50, renderItem: (e) => <RenderImage item={e} poiImage={img} poiId={poi.id} callback={setImageToOpen} /> });
            }
        }
    }

    const getPosition = (secondTry: boolean = false, lastTry: boolean = false) => {
        setGettingPosition(true);
        const options: PositionOptions = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        };
        const error = (err: GeolocationPositionError) => {
            console.warn(`ERREUR (${err.code}): ${err.message}`);
            setGettingPosition(false);
            if (err.PERMISSION_DENIED)
                showNotification(intl.formatMessage({ defaultMessage: `Barry need access to your position to use this feature` }), "warning",);
            else if (err.POSITION_UNAVAILABLE)
                showNotification(intl.formatMessage({ defaultMessage: `Your current position is not available` }), "error");
            else if (err.TIMEOUT)
                showNotification(intl.formatMessage({ defaultMessage: `The position request timed out` }), "error");
            else
                showNotification(intl.formatMessage({ defaultMessage: `You are not a the correct location, please click 'Route'` }), "warning",);
        };

        const success = (pos: any) => {
            if(mission === undefined){
                return
            }
            var crd = pos.coords;
            setGettingPosition(false);
            // console.log(`Latitude : ${crd.latitude}`);
            // console.log(`Longitude : ${crd.longitude}`);
            // console.log(`La précision est de ${crd.accuracy} mètres.`);
            let actualPosition = { lat: crd.latitude, lon: crd.longitude };
            if (poi === undefined || poi.latitude === undefined || poi.longitude === undefined) {
                showNotification(intl.formatMessage({ defaultMessage: 'The mission does not have location data' }), "error");
                return;
            }
            let missionPosition = { lat: poi.latitude, lon: poi.longitude };

            let distanceBetweenPositions = haversine(actualPosition, missionPosition);

            if (distanceBetweenPositions > 0.100) {
                // if (lastTry) {
                showNotification(intl.formatMessage({ defaultMessage: `You are {meters} meters away from the location. Use the 'Route' button` }, { meters: (distanceBetweenPositions * 1000).toFixed(0) }), "error");
                setGeolocDenied(true);
                setPositionValidated(false);
                setLongWaitPositionMessage(undefined);
                Network.setIsInPlace(false, crd.accuracy, mission.id, crd.latitude, crd.longitude, Number(eventId));
                return;
            }

            Network.setIsInPlace(true, crd.accuracy, mission.id, crd.latitude, crd.longitude, Number(eventId))
                .then(
                    reponse => {
                        setPositionValidated(true);
                        showNotification(intl.formatMessage({ defaultMessage: 'Position validated' }), "success");
                    },
                    error => {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occured while confirming the position' }), "error");
                    }
                )
                .finally(
                    () => {
                        setLongWaitPositionMessage(undefined);
                    }
                );

            // let url = `https://www.google.com/maps/search/?api=1&query=${crd?.latitude},${crd?.longitude}`
            // window.open(url, '_blank', 'noopener,noreferrer');
        };

        if (teamMission && mission !== undefined)
            Network.setIsInPlace(true, 0, mission.id, 0, 0, Number(eventId))
                .then(
                    () => {
                        setGettingPosition(false);
                        setPositionValidated(true);
                        showNotification(intl.formatMessage({ defaultMessage: 'Position validated' }), "success");
                    },
                    () => {
                        setGettingPosition(false);
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occured while confirming the position' }), "error");
                    }
                );
        else
            navigator.geolocation.getCurrentPosition(success, error, options);
    };

    const updateConfirmed = useCallback((missionId: number) => {
        setMissionConfirmed(true);
    }, []);

    const confirmMission = useCallback(() => {
        if(mission === undefined){
            return
        }
        setMissionConfirmedLoading(true);
        Network.setConfirmed(true, mission.id, Number(eventId)).then(
            reponse => {
                updateConfirmed(mission.id);
                showNotification(intl.formatMessage({ defaultMessage: 'Mission validated' }), "success");
                setMissionConfirmed(true);
                setMissionConfirmedLoading(false);
            },
            error => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occured while confirming the mission' }), "error");
                setMissionConfirmedLoading(false);
            });
    }, [mission, eventId, updateConfirmed, intl]);


    const renderIOSHelp = () => {
        return (
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} style={{ fontWeight: 'bold', fontSize: 18 }}><FormattedMessage defaultMessage={'If the popup did not open'} /></Col>
                <Col xs={{ span: 24 }} style={{ fontWeight: 'bold', fontSize: 16 }}><FormattedMessage defaultMessage={'Check Privacy & Security'} /></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faGear} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Open your phone settings'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faHand} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Go to Privacy & Security'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faPaperPlane} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Go on Location Services'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCompass} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Select Safari'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faQuestion} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Select Ask Next Time Or When I Share'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCheck} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Try confirming your position'} /></span></Col>

                <Col xs={{ span: 24 }} style={{ fontWeight: 'bold', fontSize: 16 }}><FormattedMessage defaultMessage={'Check website settings'} /></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faA} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Click on the left of the website URL'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faGlobe} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Go on Website Settings'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCompass} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Make sure that Location is on Ask or Allow'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCheck} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Try confirming your position'} /></span></Col>
            </Row>
        );

    };

    const renderAndroidHelp = () => {
        return (
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} style={{ fontWeight: 'bold', fontSize: 18 }}><FormattedMessage defaultMessage={'If the popup did not open'} /></Col>
                <Col xs={{ span: 24 }} style={{ fontWeight: 'bold', fontSize: 16 }}><FormattedMessage defaultMessage={'Check your browser permissions'} /></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faGear} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Open your phone settings'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faGrid2} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Go to Apps'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faGlobe} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Select your browser'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faShield} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Go to Permissions'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faPaperPlane} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Select Location'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCompass} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Select Ask every time'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCheck} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Try confirming your position'} /></span></Col>

                <Col xs={{ span: 24 }} style={{ fontWeight: 'bold', fontSize: 16 }}><FormattedMessage defaultMessage={'Check website settings'} /></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faLock} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Click on the left of the website URL'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faShield} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Go to Permissions'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCompass} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Allow the location or Reset permissions'} /></span></Col>
                <Col xs={{ span: 24 }} style={{ display: 'flex', alignContent: 'center' }}><CustomIcon icon={faCheck} color={'#00B0EF'} iconSize={20} style={{ margin: 'auto 0px auto 0px', width: 20 }} /> <span style={{ margin: 'auto auto auto 5px' }}><FormattedMessage defaultMessage={'Try confirming your position'} /></span></Col>
            </Row>
        );
    };


    const missionTitle = useMemo(() => {
        if(mission === undefined)
            return ''
        return <span>{` ${mission.poi?.title}${mission.title ? ` - ${mission.title}`:''}`}</span>
    }, [mission])

    return (
        <Container backTo={{ title: intl.formatMessage({ defaultMessage: 'Missions' }), link: `event/${eventId}/missions`, previous: false }}>
            <Card bodyStyle={{padding: 8}} title={<span><FormattedMessage defaultMessage={'Mission:'} />{missionTitle}</span>}>

            <Row gutter={[10, 10]}>
                {
                    missionLoading || mission === undefined || !poi ?
                        <Col xs={{ span: 24 }}>
                            <LoadingComponent />
                        </Col>
                        :
                        <Col xs={{ span: 24 }}>
                            {
                                teamMission &&
                                <>
                                    <Title level={2}><FormattedMessage defaultMessage={'User'} /></Title>
                                    <span>
                                        {mission.username}
                                        {
                                            mission.userMobile && mission.userMobile.length > 0 &&
                                            <a style={{ marginLeft: '5px' }} href={`tel:${mission.userMobile}`}><CustomIcon icon={faPhone} /></a>
                                        }
                                    </span>

                                </>
                            }
                            <Title level={2} style={teamMission ? { marginTop: '25px' } : {}} >{intl.formatMessage({defaultMessage: 'Hours'})}</Title>
                            <span><FormattedMessage defaultMessage={'On {date} from {start} to {end}'} values={{ date: moment(mission.startDate).format("DD MMM"), start: moment(mission.startDate).format(getFormat('TIME_SHORT')), end: moment(mission.endDate).format(getFormat('TIME_SHORT')) }} /></span>
                            {
                                mission.description &&
                                <>
                                    <Title style={{ marginTop: '25px' }} level={2}><FormattedMessage defaultMessage={'Description'} /></Title>
                                    <p>{mission.description}</p>
                                </>
                            }

                            <Title style={{ marginTop: '25px' }} level={2}><FormattedMessage defaultMessage={'Location'} /></Title>

                            <Row gutter={[5, 10]} style={teamMission ? {} : { marginTop: '10px' }}>
                                <Col xs={appWidth < 410 ? { span: 24 } : { span: 12 }}>
                                    <p style={{ maxWidth: 'calc(100vw - 50px)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '100%' }}>
                                        <span>{mission.poi ? mission.poi.title : <FormattedMessage defaultMessage={'Not assigned to a position'} />}</span>
                                    </p>
                                </Col>
                                <Col xs={appWidth < 410 ? { span: 24 } : { span: 12 }}>
                                    <Button disabled={poiLoading || !mission.poi} style={{ width: '100%' }} onClick={() => {
                                        let url = `https://www.google.com/maps/search/?api=1&query=${poi?.latitude},${poi?.longitude}`;
                                        window.open(url, '_blank', 'noopener,noreferrer');
                                    }} ><FormattedMessage defaultMessage={'Route'} /></Button>
                                </Col>
                                <Col xs={appWidth < 410 ? { span: 24 } : { span: 12 }}>
                                    <Popconfirm
                                        title={<FormattedMessage defaultMessage={'This is not your mission. Are you sure you want to confirm the mission for this user?'} />}
                                        okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                        onConfirm={() => confirmMission()}
                                        disabled={!teamMission}
                                        className='mission-detail-popconfirm'
                                    >
                                        <Button
                                            loading={missionConfirmedLoading}
                                            style={{ width: '100%' }}
                                            disabled={missionConfirmed || poiLoading || !mission.poi}
                                            type={missionConfirmed ? "default" : "primary"}
                                            onClick={() => {
                                                if (!teamMission)
                                                    confirmMission();
                                            }}
                                        >
                                            {
                                                missionConfirmed ?
                                                    <FormattedMessage defaultMessage={'Mission confirmed'} />
                                                    :
                                                    <FormattedMessage defaultMessage={"Confirm mission"} />
                                            }
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col xs={appWidth < 410 ? { span: 24 } : { span: 12 }}>
                                    <Popconfirm
                                        title={<FormattedMessage defaultMessage={'This is not your mission. Are you sure you want to confirm the position for this user?'} />}
                                        okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                        onConfirm={() => getPosition()}
                                        disabled={!teamMission}
                                        className='mission-detail-popconfirm'
                                    >
                                        <Button
                                            loading={gettingPosition}
                                            style={{ width: '100%' }}
                                            disabled={positionValidated || poiLoading || !mission.poi}
                                            type={missionConfirmed ? "primary" : "default"}
                                            onClick={() => {
                                                if (!teamMission)
                                                    getPosition();
                                            }}
                                        >
                                            {
                                                positionValidated ?
                                                    <FormattedMessage defaultMessage={'Position confirmed'} />
                                                    :
                                                    <FormattedMessage defaultMessage={"Confirm position"} />
                                            }
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                {
                                    geolocDenied ?
                                        <p onClick={() => setGeolocHelp(true)} style={{ color: "#00B0EF", textAlign: 'center', marginTop: 10, width: '100%', cursor: 'pointer' }}><FormattedMessage defaultMessage={'Having trouble with position confirmation?'} /></p>
                                        :
                                        undefined
                                }
                                {
                                    longWaitPositionMessage &&
                                    <Col xs={{ span: 24 }}>
                                        <p className="fade-in" style={{ fontStyle: 'italic', color: "#00B0EF", textAlign: 'center', fontSize: '95%' }}>{longWaitPositionMessage}</p>
                                    </Col>
                                }
                            </Row>

                            {/* <a rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${poi?.latitude},${poi?.longitude}`} target="_blank">{"(ouvrir google maps)"}</a> */}

                            {/* TODO: pour les icones, voir: https://stackleap.io/js/react-image-gallery rechercher renderItem */}

                            {
                                poiLoading ?
                                    <Row>
                                        <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                            <Spin />
                                        </Col>
                                    </Row>
                                    :
                                    images ?
                                        <>
                                            <Title style={{ marginTop: '25px' }} level={2}>Images:</Title>
                                            <ImageGallery
                                                items={images}
                                                lazyLoad={true}
                                                showPlayButton={false}
                                                showFullscreenButton={false}
                                            />
                                        </>
                                        :
                                        null
                            }
                        </Col>
                }
                {
                    imageToOpen && (
                        <Lightbox
                            mainSrc={imageToOpen}
                            onCloseRequest={() => setImageToOpen(undefined)}
                        />
                    )
                }
            </Row>
            {
                geolocHelp ?
                    <Modal
                        title={intl.formatMessage({ defaultMessage: 'Help with position confirmation' })}
                        visible
                        onCancel={() => setGeolocHelp(false)}
                        footer={[]}
                    >
                        {
                            isIOS ?
                                renderIOSHelp()
                                :
                                renderAndroidHelp()
                        }
                    </Modal>
                    : undefined
            }
            </Card>
        </Container>
    );
};
export default MissionDetail;