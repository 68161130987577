import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

interface IProps {
    iconSize?: number;
    duoTone?: string;
    color?: string;
    opacity?: number;
    iconStroke?: {
        color: string;
        width?: number;
        opacity?: number;
    };
}

type Props = IProps & FontAwesomeIconProps;

const CustomIcon = (props: Props) => {
    const style = {
        "--fa-primary-color": props.duoTone, // colors defined elsewhere
        "--fa-secondary-color": props.duoTone,
        "--fa-secondary-opacity": props.opacity ? props.opacity : ''
    };
    return <FontAwesomeIcon
        {...props}
        className={`anticon fontawesome-customicon ${props.className}`}
        style={{
            ...props.style,
            ...style,
            fontSize: props.iconSize ? props.iconSize : 16,
            color: !props.duoTone && props.color ? props.color : '',
            stroke: props.iconStroke?.color ? props.iconStroke.color : '',
            strokeWidth: props.iconStroke?.width ? props.iconStroke.width : props.iconStroke?.color ? 20 : '',
            strokeOpacity: props.iconStroke?.opacity ? props.iconStroke.opacity : props.iconStroke?.color ? 20 : '',
        }}
    />;
};

export default CustomIcon;